<template>
  <div class="page">
    <!-- 头部navBar -->
    <div class="nav">
      <span
        :class="item.select ? 'checkedItem' : 'item'"
        @click="selectNavType(item, index)"
        v-for="(item, index) in navList"
        :key="index"
      >
        <span class="point"></span>
        {{ item.message }}
      </span>
    </div>
    <!-- 列表 -->
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toDetail(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="box_top">
            <img src="./img/hot.png" alt="" v-if="index < 3" />
            <div class="title">{{ item.topicTitle }}</div>
          </div>
          <v-h5Mtml :content="item.topicContent"></v-h5Mtml>
          <div class="box_bottom">
            <img src="./img/watch.png" alt="" />
            <div class="watchNum">{{ item.readCount }}</div>
            <img src="./img/message.png" alt="" />
            <div class="messageNum">{{ item.praiseCount }}</div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListUrl, getLifeNewsTypeUrl } from "./api.js";
export default {
  name: "lifeExpertList",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },

      navList: [
        {
          message: "全部",
          code: [],
          select: true,
        },
      ],
      topicTypes: [],
      topicType: null,
      nowType: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  mounted() {
    this.getNavList();
  },
  methods: {
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/lifeExpertDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        topicTypes: localStorage.getItem("topicTypes"),
        topicType: this.topicType,
        userId: this.userId,
      };
      this.$axios
        .get(`${getTopicListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
    },
    getNavList() {
      this.$axios.get(`${getLifeNewsTypeUrl}`).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            item.select = false;
            this.navList.push(item);
          });
          console.log(this.navList);
        }
      });
    },
    selectNavType(oItem, oIndex) {
      if (oItem.select == true) {
        return;
      } else {
        this.navList.forEach((item) => {
          item.select = false;
        });
        oItem.select = true;
        if (oItem.message == "全部") {
          this.topicTypes = oItem.code;
          this.topicType = null;
        } else {
          this.topicTypes = null;
          this.topicType = oItem.code;
        }
        this.listData = [];
        this.requestData.curPage = 1;
        this.finished = false;
        this.onLoad();
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding-top: 52px;
  box-sizing: border-box;
  .list {
    margin-top: 36px;
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 256px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      margin-bottom: 30px;
      padding: 40px 22px 24px 40px;
      box-sizing: border-box;
      .box_bottom {
        width: 100%;
        display: flex;
        align-items: center;
        img {
          width: 32px;
          display: block;
          margin-right: 8px;
        }
        .watchNum {
          margin-right: 32px;
        }
        div {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.25);
        }
      }
      .box_center {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        height: 65px;
        margin-bottom: 34px;
        overflow: hidden;
        text-overflow: ellipsis; //显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 2; //块元素显示的文本行数
        -webkit-box-orient: vertical;
        /deep/ p,
        /deep/ h2,
        /deep/ h1,
        /deep/ strong,
        /deep/ span {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5) !important;
          line-height: 34px;
        }
      }
      .box_top {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        img {
          width: 28px;
          height: 36px;
          display: block;
          margin-right: 10px;
        }
        .title {
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .item:nth-last-child(1) {
      margin-bottom: 60px;
    }
  }
  .nav {
    padding: 0 32px;
    background: #fafafa;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    .checkedItem {
      height: 52px;
      line-height: 52px;
      font-size: 24px;
      // text-align: center;
      padding: 10px 32px;
      padding: 10px 32px 10px 48px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 4px 18px 0px rgba(227, 227, 227, 0.5);
      border-radius: 28px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 22px;
      position: relative;
      .point {
        width: 26px;
        height: 26px;
        background: #fe9514;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
    }
    .item {
      height: 52px;
      line-height: 52px;
      font-size: 24px;
      text-align: center;
      padding: 10px 32px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 4px 18px 0px rgba(227, 227, 227, 0.5);
      border-radius: 28px;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 22px;
    }
    .item:nth-last-child(1) {
      margin-right: 0;
    }
  }
  .nav::-webkit-scrollbar {
    display: none;
  }
}
</style>
